'use client';
import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import {
  LogOut,
  CreditCard,
  Settings,
  KeyRound,
  UserIcon,
  Coins,
  EyeOff,
  Shield
} from 'lucide-react';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import { roundNumber } from '@/utils/helpers';
import { getUserCredits, getUserEmail } from '@/utils/account/user';
import SignoutButton from './signout-button';
import { Button } from '@/components/ui/button';
import StopImpersonationForm from '@/app/(app)/components/stop-impersonation-form';
import { isAdmin, isImpersonated } from '@/utils/auth-helpers/server';

export default function AccountMenu() {
  const [impersonatedUserId, setImpersonatedUserId] = useState<string | null>(
    null
  );
  const [userEmail, setUserEmail] = useState<string>('Account');
  const [creditsLeft, setCreditsLeft] = useState<number | string>('...');
  const [impersonated, setImpersonated] = useState<boolean>(false);
  const [admin, setAdmin] = useState<boolean>(false);

  useEffect(() => {
    // Initialize state from localStorage
    const storedImpersonationId = localStorage.getItem('impersonatedUserId');
    setImpersonatedUserId(storedImpersonationId);

    // Handle storage changes
    const handleStorageChange = () => {
      const storedImpersonationId = localStorage.getItem('impersonatedUserId');
      setImpersonatedUserId(storedImpersonationId);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const fetch = async () => {
      setUserEmail((await getUserEmail()) || 'Account');
      setCreditsLeft(roundNumber((await getUserCredits()) || 0, 1));
      setAdmin(await isAdmin());
      setImpersonated(await isImpersonated());
    };

    fetch();
  }, [impersonatedUserId]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="iconSm" className="mr-4">
          <UserIcon className="size-[1.2rem]" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        sideOffset={6}
        className="w-[18rem] invstyle shadow-xl bg-popover/80 backdrop-blur border-none text-foreground"
      >
        <DropdownMenuLabel className="flex justify-between items-center">
          {userEmail}
          <span className="flex py-1 pr-2 items-center">
            <Coins className="size-4 mr-1" /> {creditsLeft}
          </span>
        </DropdownMenuLabel>

        <DropdownMenuSeparator className="bg-muted/30" />

        <DropdownMenuItem className="cursor-pointer" asChild>
          <Link href="/account/settings" className="gap-x-2">
            <Settings className="size-4" />
            Settings
          </Link>
        </DropdownMenuItem>

        <DropdownMenuItem className="cursor-pointer" asChild>
          <Link href="/account/api-keys" className="gap-x-2">
            <KeyRound className="size-4" />
            API Keys
          </Link>
        </DropdownMenuItem>

        <DropdownMenuItem className="cursor-pointer" asChild>
          <Link href="/account/billing" className="gap-x-2">
            <CreditCard className="size-4" />
            Billing
          </Link>
        </DropdownMenuItem>

        {impersonated && (
          <DropdownMenuItem className="cursor-pointer">
            <StopImpersonationForm className="flex gap-x-2 items-center">
              <EyeOff className="size-4" />
              Stop impersonation
            </StopImpersonationForm>
          </DropdownMenuItem>
        )}
        {!impersonated && admin && (
          <DropdownMenuItem className="cursor-pointer" asChild>
            <Link href="/admin/users" className="gap-x-2">
              <Shield className="size-4" />
              Admin panel
            </Link>
          </DropdownMenuItem>
        )}

        {/* <DropdownMenuSeparator className="bg-border/50" /> */}
        <DropdownMenuItem className="flex w-full">
          <SignoutButton className="w-full h-full">
            <LogOut className="size-4" />
            Log out
          </SignoutButton>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
